<template>
  <div>
    <c-table
      title="대상자 정보"
      class="q-mb-md"
      :columns="grid.columns"
      :data="grid.data"
      customDataTr
      gridHeightAuto
      hideHeader
      hideBottom
      :isTitle="true"
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
      <template v-slot:customDataTr="props">
        <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              반 / 팀
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <span>
              {{resultData.upDeptName + ' / ' + resultData.deptName}}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              사번 / 성명
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <span>
              {{resultData.empNo + ' / ' + resultData.userName}}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              병원 / 검진일
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <span>
              {{resultData.hospitalName + ' / ' + resultData.checkupDate}}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              생년월일
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <span>
              {{resultData.birthDate}}
            </span>
          </q-td>
        </q-tr>
      </template>
    </c-table>
    
    <c-table
      title="검진결과 상세"
      class="q-mb-md"
      :columns="grid2.columns"
      :data="grid2.data"
      customDataTr
      gridHeightAuto
      hideHeader
      hideBottom
      :isTitle="true"
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
      <template v-slot:customDataTr="props">
        <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
          <span v-for="(data, idx) in resultData.examineList" :key="idx">
            <q-td
              class="col-2 text-center th-td-style th-style min-size-td"
              :rowspan="1">
              <span>
                {{ data['examineName'] }} 
              </span>
            </q-td>
            <q-td
              class="col-2 text-center th-td-style min-size-td2" 
              :rowspan="1">
              <span>
                {{ data['examineResult'] }} 
              </span>
            </q-td>
          </span>
          <br>
          <br>
        </q-tr>
      </template>
    </c-table>
    <!-- <q-form ref="editForm">
      <c-card v-if="resultData.examineList && resultData.examineList.length > 0" class="cardClassDetailForm" title="검진결과 상세">
        <template slot="card-detail">
          <div class="col-2" v-for="(data, idx) in resultData.examineList" :key="idx">
            <c-text
              :disabled="disabled"
              :editable="editable"
              :label="data.examineName"
              :name="`value${idx}`"
              v-model="data.examineResult"
            />
          </div>
        </template>
      </c-card>
    </q-form> -->
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'check-up-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaCheckupResultId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      editable: false,
      resultData: {
        upDeptName: '',
        deptName: '',
        userName: '',
        empNo: '',
        checkupDate: '',
        birthDate: '',
        hospitalName: '',
        examineList: [],
      },
      grid: {
        columns: [
          {
            name: 'header1',
            field: 'header1',
            label: 'header1',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data1',
            field: 'data1',
            label: 'data1',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'header2',
            field: 'header2',
            label: 'header2',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data2',
            field: 'data2',
            label: 'data2',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'header3',
            field: 'header3',
            label: 'header3',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data3',
            field: 'data3',
            label: 'data3',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'header4',
            field: 'header4',
            label: 'header4',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data4',
            field: 'data4',
            label: 'data4',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
        ],
        data: [{}, {}, {}, {}, {},],
      },
      grid2: {
        columns: [
          {
            name: 'header1',
            field: 'header1',
            label: 'header1',
            align: 'center',
            sortable: false,
            style: 'width:20%',
          },
          {
            name: 'data1',
            field: 'data1',
            label: 'data1',
            align: 'center',
            sortable: false,
            style: 'width:20%',
          },
          {
            name: 'header2',
            field: 'header2',
            label: 'header2',
            align: 'center',
            sortable: false,
            style: 'width:20%',
          },
          {
            name: 'data2',
            field: 'data2',
            label: 'data2',
            align: 'center',
            sortable: false,
            style: 'width:20%',
          },
          {
            name: 'header3',
            field: 'header3',
            label: 'header3',
            align: 'center',
            sortable: false,
            style: 'width:20%',
          },
          {
            name: 'data3',
            field: 'data3',
            label: 'data3',
            align: 'center',
            sortable: false,
            style: 'width:20%',
          },
          {
            name: 'header4',
            field: 'header4',
            label: 'header4',
            align: 'center',
            sortable: false,
            style: 'width:20%',
          },
          {
            name: 'data4',
            field: 'data4',
            label: 'data4',
            align: 'center',
            sortable: false,
            style: 'width:20%',
          },
          {
            name: 'header5',
            field: 'header5',
            label: 'header5',
            align: 'center',
            sortable: false,
            style: 'width:20%',
          },
          {
            name: 'data5',
            field: 'data5',
            label: 'data5',
            align: 'center',
            sortable: false,
            style: 'width:20%',
          },
        ],
        data: [{}, {}, {}, {}, {}, {}],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.hea.checkup.result.get.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.heaCheckupResultId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.heaCheckupResultId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.resultData = _result.data
        },);
      } 
    },
  }
};
</script>

<style lang="sass">
.th-td-style
  vertical-align: middle !important

.th-style-base
  background: #EEEEEE !important
  font-weight: 700

.th-style
  background: #EEEEEE !important
  font-weight: 700

.th-style-1
  width: 10%

.td-style-1
  width: 15%

.td-style-2
  width: 65%

.td-style-3
  width: 90%

.custom-text-before-parent
  .custom-text-before
    font-size: 12px !important
    height: 22px !important
    min-height: 22px !important
    line-height: 22px
  .q-field--dense .q-field__inner
    padding-bottom: 0 !important
    padding-top: 3px !important
  .customText.q-field--dense .q-field__control, .customText.q-field--dense .q-field__marginal
    height: 22px !important
    min-height: 22px !important

.lastQue
  label
    padding-bottom: 0px !important

.min-size-td
  min-width: 200px
.min-size-td2
  min-width: 165px
  line-height: 2em
  text-align: left !important
</style>