var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        staticClass: "q-mb-md",
        attrs: {
          title: "대상자 정보",
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          customDataTr: "",
          gridHeightAuto: "",
          hideHeader: "",
          hideBottom: "",
          isTitle: true,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customDataTr",
            fn: function (props) {
              return [
                props.rowIndex === 0
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 반 / 팀 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.resultData.upDeptName +
                                      " / " +
                                      _vm.resultData.deptName
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 사번 / 성명 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.resultData.empNo +
                                      " / " +
                                      _vm.resultData.userName
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 병원 / 검진일 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.resultData.hospitalName +
                                      " / " +
                                      _vm.resultData.checkupDate
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 생년월일 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.resultData.birthDate) + " "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-table", {
        staticClass: "q-mb-md",
        attrs: {
          title: "검진결과 상세",
          columns: _vm.grid2.columns,
          data: _vm.grid2.data,
          customDataTr: "",
          gridHeightAuto: "",
          hideHeader: "",
          hideBottom: "",
          isTitle: true,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customDataTr",
            fn: function (props) {
              return [
                props.rowIndex === 0
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _vm._l(
                          _vm.resultData.examineList,
                          function (data, idx) {
                            return _c(
                              "span",
                              { key: idx },
                              [
                                _c(
                                  "q-td",
                                  {
                                    staticClass:
                                      "col-2 text-center th-td-style th-style min-size-td",
                                    attrs: { rowspan: 1 },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " + _vm._s(data["examineName"]) + " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "q-td",
                                  {
                                    staticClass:
                                      "col-2 text-center th-td-style min-size-td2",
                                    attrs: { rowspan: 1 },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(data["examineResult"]) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _c("br"),
                        _c("br"),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }